import { useEffect, useState } from 'react'
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useOtpAuthQuery, useOtpLoginMutation } from '@igblsln/store';
import './styles.scss'
import { useToast } from '@igblsln/control';
import { Link, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';

type Props = {}

const Login = (props: Props) => {
    const { showError } = useToast()
    const navigate = useNavigate()
    const [lastPhoneNo, setLastPhoneNo] = useState<number>(0);
    const [phoneNo, setPhoneNo] = useState<string>();
    const [sessionId, setSessionId] = useState<string>();
    const [otp, setOtp] = useState<string | undefined>('');
    const { data, isLoading, error } = useOtpAuthQuery(lastPhoneNo, {
        skip: !lastPhoneNo,
        refetchOnMountOrArgChange: true
    });
    const [otpLogin] = useOtpLoginMutation()

    const handleLogin = async () => {
        if (otp && sessionId && lastPhoneNo) {
            try {
                await otpLogin({
                    phone: parseInt(lastPhoneNo?.toString()),
                    session_id: sessionId,
                    otp: parseInt(otp)
                }).unwrap()
                navigate('/')
            } catch (err) {
                // @ts-ignore
                showError('Error', err?.data?.message || 'There was an error while login!')

            }
        }
    }

    useEffect(() => {
        if (phoneNo && phoneNo.length === 10) {
            setLastPhoneNo(parseInt(phoneNo))
        }
    }, [phoneNo])

    useEffect(() => {
        setOtp(data?.otp);
        setSessionId(data?.Details);
    }, [data])

    useEffect(() => {

        if (error) {
            console.log(error)
            // @ts-ignore
            showError('Error', error?.data?.message || 'There was an error while login!')
        }
    }, [error, showError])

    return (
        <div className='ig-login min-h-screen'>
            <div className='px-4 py-8 md:px-6 lg:px-8'>
                <div className="flex align-items-center justify-content-center">
                    <div className="surface-card p-4 shadow-2 border-round">
                        <div className="text-center mb-5">
                            <img src="hook128.png" alt="hyper" height="50" className="mb-3" />
                            <div className="text-900 text-3xl font-medium mb-3">Constrogen</div>
                            <span className="text-600 font-medium line-height-3">Don't have an account?</span>
                            <Link to="/register"
                                className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
                                Create today!
                            </Link>
                        </div>

                        <div>
                            <label htmlFor="mobileno" className="block text-900 font-medium mb-2">Mobile Number</label>
                            <span className="p-input-icon-right w-full mb-3">
                                {isLoading && <i className="pi pi-spin pi-spinner" />}
                                <InputText maxLength={10} id="mobileno" className="w-full" keyfilter="int" value={phoneNo}
                                    onChange={(e) => setPhoneNo(e.target.value)} />
                                {/* <InputMask id="mobileno" className="w-full" mask="9999999999" value={phoneNo}
                                    placeholder="9999999999" onComplete={handleChange}
                                    onChange={(e) => setPhoneNo(e.value)} ></InputMask> */}
                            </span>

                            <label htmlFor="password" className="block text-900 font-medium mb-2">One Time Password (OTP)</label>
                            <Password value={otp} onChange={(e) => setOtp(e.target.value)}
                                toggleMask feedback={false}
                                pattern="[0-9]*"
                                inputMode="numeric"
                                maxLength={4}
                                className="w-full mb-3"
                                inputClassName="w-full" />

                            <div className="flex align-items-center justify-content-between mb-6">
                                <div className="flex align-items-center">
                                    {/* <Checkbox inputId="rememberme1" className="mr-2" onChange={e => setChecked(e.checked)} checked={checked} />
                                <label htmlFor="rememberme1">Remember me</label> */}
                                </div>
                                <a href='/#' className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Resend OTP?</a>
                            </div>

                            <Button label="Sign In" icon="pi pi-user" onClick={handleLogin} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login