import { api } from '../service';
import { tags } from '../constants';
import { urlUtils } from '../util';
export const itemsApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getAllItem: build.query({
            query: () => {
                return {
                    url: urlUtils('/inventory/item/', `?without_pagination=1`),
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.INVENTORY_ITEM, id })),
                { type: tags.INVENTORY_ITEM, id: 'LIST' },
            ] : [{ type: tags.INVENTORY_ITEM, id: 'LIST' }],
        }),
        getAllItemTypes: build.query({
            query: () => {
                return {
                    url: urlUtils('/inventory/item_type/', `?without_pagination=1`),
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.INVENTORY_ITEM, id })),
                { type: tags.INVENTORY_ITEM, id: 'LIST' },
            ] : [{ type: tags.INVENTORY_ITEM, id: 'LIST' }],
        }),
        getItemTypesForVendor: build.query({
            query: (vendorId) => {
                return {
                    url: urlUtils(`/inventory/item_type?vendor_key=${vendorId}&without_pagination=1`),
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "ITEM_TYPES_FOR_VENDOR", id })),
                { type: "ITEM_TYPES_FOR_VENDOR", id: 'LIST' },
            ] : [{ type: "ITEM_TYPES_FOR_VENDOR", id: 'LIST' }],
        }),
        getItemsForItemType: build.query({
            query: (itemType) => {
                return {
                    url: `/inventory/item/?item_type=${itemType}&without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ITEMS_FOR_ITEM_TYPE, id })),
                { type: tags.ITEMS_FOR_ITEM_TYPE, id: 'LIST' },
            ] : [{ type: tags.ITEMS_FOR_ITEM_TYPE, id: 'LIST' }],
        }),
        getItemsForVendor: build.query({
            query: (vendor) => {
                return {
                    url: `/inventory/item/?vendor=${vendor}&without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ITEMS_FOR_ITEM_TYPE, id })),
                { type: tags.ITEMS_FOR_ITEM_TYPE, id: 'LIST' },
            ] : [{ type: tags.ITEMS_FOR_ITEM_TYPE, id: 'LIST' }],
        }),
        getItemSubTypeForItemType: build.query({
            query: (itemType) => {
                return {
                    url: `/inventory/item_subtype/?item_type=${itemType}`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ITEMS_FOR_ITEM_TYPE, id })),
                { type: tags.ITEMS_FOR_ITEM_TYPE, id: 'LIST' },
            ] : [{ type: tags.ITEMS_FOR_ITEM_TYPE, id: 'LIST' }],
        }),
    }),
});
export const { useGetAllItemQuery, useGetAllItemTypesQuery, useGetItemTypesForVendorQuery, useGetItemsForItemTypeQuery, useGetItemsForVendorQuery, useGetItemSubTypeForItemTypeQuery } = itemsApi;
