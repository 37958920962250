import { api } from '../service';
import { tags } from '../constants';
import { urlUtils } from '../util';
export const commonApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getNextDocNo: build.query({
            query: (docid) => {
                return {
                    url: urlUtils(`/transaction/doc/id/next?docid=${docid}`),
                };
            },
        }),
        activeCustomers: build.query({
            query: () => {
                return {
                    // url: `/sale/customer/all/active`,
                    url: `/sale/customer/?without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ACTIVE_CUSTOMERS, id })),
                { type: tags.ACTIVE_CUSTOMERS, id: 'LIST' },
            ] : [{ type: tags.ACTIVE_CUSTOMERS, id: 'LIST' }],
        }),
        activeBookings: build.query({
            query: () => {
                return {
                    url: `/sales/bookings/all/active`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ACTIVE_BOOKINGS, id })),
                { type: tags.ACTIVE_BOOKINGS, id: 'LIST' },
            ] : [{ type: tags.ACTIVE_BOOKINGS, id: 'LIST' }],
        }),
        getModeOfPayments: build.query({
            query: () => {
                return {
                    url: `/transaction/payment/mode/`,
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: tags.MODE_OF_PAYMENT, id })),
                { type: tags.MODE_OF_PAYMENT, id: 'LIST' },
            ],
        }),
        getGRNNumbers: build.query({
            query: () => {
                return {
                    url: `/transaction/goods/receipts/?only_grn_number=True`,
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: tags.GRN_NUMBER, id })),
                { type: tags.GRN_NUMBER, id: 'LIST' },
            ],
        }),
        getItemKitsForParams: build.query({
            query: ({ itemType, purpose }) => {
                return {
                    url: `/templates/item_kit?without_pagination=1${itemType ? `&item_type=${itemType}` : ''}${purpose ? `&purpose_key=${purpose}` : ''}`,
                };
            },
        }),
        getItemKitsDetail: build.query({
            query: (id) => {
                return {
                    url: `/templates/item_kit/${id}`,
                };
            },
        }),
        getActivePurchaseTemplates: build.query({
            query: () => {
                return {
                    url: `/transaction/purchase/template/all/active`,
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: "ACTIVE_PURCHASE_TEMPLATE", id })),
                { type: "ACTIVE_PURCHASE_TEMPLATE", id: 'LIST' },
            ],
        }),
        getActiveProjects: build.query({
            query: () => {
                return {
                    url: `/project/project/all/active`,
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: "ACTIVE_PROJECT", id })),
                { type: "ACTIVE_PROJECT", id: 'LIST' },
            ],
        }),
        getActivePurchaseTemplatesForItemType: build.query({
            query: (number) => {
                return {
                    url: `/transaction/purchase/template/all/active?item_type=${number}`,
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: tags.PURCHASE_TEMPLATE, id })),
                { type: tags.PURCHASE_TEMPLATE, id: 'LIST' },
            ],
        }),
        downloadExportedData: build.query({
            query: ({ name, filter }) => {
                return {
                    url: `/exportdata?table=${name}&filter=${filter}`,
                };
            },
        }),
        getPurposesForItemTypes: build.query({
            query: (id) => {
                return {
                    url: urlUtils(`/inventory/purpose/${id}?without_pagination=1`),
                };
            },
        }),
    }),
});
export const { useGetNextDocNoQuery, useActiveCustomersQuery, useActiveBookingsQuery, useGetModeOfPaymentsQuery, useGetGRNNumbersQuery, useGetActiveProjectsQuery, useGetActivePurchaseTemplatesQuery, useGetActivePurchaseTemplatesForItemTypeQuery, useDownloadExportedDataQuery, useGetItemKitsForParamsQuery, useGetItemKitsDetailQuery,
// useGetPurposesForItemTypesQuery
 } = commonApi;
